html,body { font-family: $defaultfont; }

body { opacity: 0; 
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -ms-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
}

.tw-fav-icon { cursor: pointer; }

.btn {
    border-radius: $border-radius-none;
}

.form-control {
    border-radius: $border-radius-none;
    height: $input-height-base + 5;

    &.bg-danger {
        background-color: $state-danger-bg;
    }

    &.bg-success {
        background-color: $state-success-bg;
    }
}

.panel-group .panel {
    border-radius: $border-radius-none;
}

.btn.btn-success {
    background: $green;
    border-color: $green;
}

.btn.btn-loadmore {
    min-width: 250px;
    font-weight: bold;
}

.fullwidth { position: relative; margin: 0; padding: 0; overflow: hidden; width: 100%; }
.tw-spacer { min-height: 40px; clear:both; }

.forcewhite {
    background: $white !important;
}
.forcewhitecolor {
    color: $white !important;
}

.tw-content-area {
    padding-bottom:40px;
    &:nth-child(odd) {
        background-color: $lightgraybackground;
    }
}

.tw-alternatecolor {
    &.tw-lightgraybackground { background-color: $lightgraybackground; }
    &.tw-white { background-color: $white; }
}

@media (min-width: $screen-sm-min) {
  // Automatically set modal's width for larger viewports
    .modal-dialog {
        width: $modal-md-alt;
        max-width: 95%;
    }
}

.modal-content {
    border-radius: $border-radius-none;
}

.errormsg {
    white-space: pre; padding:15px; text-align: center; color: darkred; word-wrap: break-word;
}

.successmsg {
    white-space: pre; padding:15px; text-align: center; color: darkgreen; word-wrap: break-word;
}

/* for vertical alligning stuff */
.tw-table-like-lbl {
    display: table;
    max-width: 100%;
    margin: 15px auto 0 auto;
    padding: 7px;
    color: $lightgreenish;
    background: $white;
    border: 1px solid $lightgreenish;
    cursor: pointer;

    .checkbox-wrapper {
        display: table-cell;
        vertical-align: middle;

        .tw-overlayed-checkbox {
            opacity: 0 !important;
            width: 28px;
            height: 28px;
            position: absolute;
            margin: 0;
            z-index: 1;
        }

        .styled-checkbox {
            border: solid 1px $lightgreenish;
            width: 26px;
            height: 26px;
            margin-right: 8px;
            vertical-align: middle;
            position: relative;
            display: inline-block;

            .styled-checkbox-inner {
                background-color: $green;
                display: inline-block;
                width: 16px;
                height: 16px;
                left: 4px;
                position: absolute;
                top: 4px;
                @include transition(opacity .2s ease-in-out);
            }
        }

        .tw-overlayed-checkbox + .styled-checkbox > .styled-checkbox-inner {
            opacity: 0;
        }

        .tw-overlayed-checkbox:checked + span.styled-checkbox > .styled-checkbox-inner {
            opacity: 1;
        }
    }

    .styled-checkbox-lbl {
        display: table-cell;
        font-weight: normal;
        vertical-align: middle;
        font-size: 12px;
    }
}